import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import "./Albums.scss";
import { fetchData } from "../../api/fetchData.js";
import { useNavigate } from "react-router-dom";

import { SERVER } from "../../Config.js";

const Albums = () => {
  const [albums, setAlbums] = useState(null);

  //fetch all albums from server
  useEffect(() => {
    const albumsPath = "/api/travels?populate=*&sort=rank:asc";
    fetchData(albumsPath).then((result) => {
      setAlbums(result.data.data);
    });
  }, []);

  const navigate = useNavigate();
  /*function navigateToRoute(route) {
        history.push(route);
      }*/

  return (
    <div className="albums">
      {albums &&
        albums.map((album) => (
          <motion.div className="cover" key={album.id} onClick={() => navigate(`/albums/${album.attributes.Title}`)}>
            <motion.div className="cover-title">{album.attributes.Title}</motion.div>
            <motion.img
              src={SERVER + album.attributes.Cover.data.attributes.formats.small.url}
              alt="uploaded pic"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.1 }}
            />
          </motion.div>
        ))}
    </div>
  );
};

export default Albums;
