import React, { useState, useEffect, useCallback, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "./Modal.scss";
import { SERVER } from "../../Config.js";
import Switch from "react-switch";
import { SlArrowRight, SlArrowLeft } from "react-icons/sl";

const Modal = ({ setSelectedImg, selectedImg, setSelectedIndex, selectedIndex, photographs }) => {
  //Initial setup: Main image, original image, loading
  getTags();
  //Image management:
  /* If possible take large resolution, if not existant take default url */
  function computeMainImage() {
    return selectedImg.attributes.Image.data.attributes.formats.large
      ? SERVER + selectedImg.attributes.Image.data.attributes.formats.large.url
      : SERVER + selectedImg.attributes.Image.data.attributes.url;
  }
  const [mainImage, setMainImage] = useState(computeMainImage);

  function computeOriginalImage() {
    if (selectedImg.attributes.OriginalImage.data != null) {
      return selectedImg.attributes.OriginalImage.data.attributes.formats.large
        ? SERVER + selectedImg.attributes.OriginalImage.data.attributes.formats.large.url
        : SERVER + selectedImg.attributes.OriginalImage.data.attributes.url;
    }
  }
  const [originalImage, setOriginalImage] = useState(computeOriginalImage);

  //image that is shown
  const [image, setImage] = useState(mainImage);
  //current click direction
  const [direction, setDirection] = useState(0);

  //on component startup set listener for click out of modal
  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  //recompute main and original image when selectedImg changes
  useEffect(() => {
    setOriginalImage(computeOriginalImage);
    setMainImage(computeMainImage);
    setImage(computeMainImage);
  }, [selectedImg]);

  //browse to images in modal
  async function nextImage() {
    setSwitchStatus([false, "Improved Image"]); //needs to be called before setDirection in order for modal img switch animation to work properly
    //await new Promise((resolve) => setTimeout(resolve, 500));
    setDirection(1);
    setSelectedImg((selectedImg) => photographs[selectedIndex + 1]);
    setSelectedIndex((selectedIndex) => selectedIndex + 1);
    getTags();
  }
  function previousImage() {
    setSwitchStatus([false, "Improved Image"]); //needs to be called before setDirection in order for modal img switch animation to work properly
    setDirection(-1);
    setSelectedImg((selectedImg) => photographs[selectedIndex - 1]);
    setSelectedIndex((selectedIndex) => selectedIndex - 1);
    getTags();
  }

  //Management of click out of modal / Esc button:
  const handleClick = (e) => {
    if (e.target.classList.contains("backdrop") || e.target.classList.contains("modal-close-button")) {
      setSelectedImg(null);
    }
  };
  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      setSelectedImg(null);
    }
  }, []);

  //originalImageSwitch management:
  let disabled = false;
  if (!originalImage) {
    disabled = true;
  }
  const [switchStatus, setSwitchStatus] = useState([false, "Improved Image"]);
  function handleOriginalSwitch(switchStatusNew) {
    setDirection(0);
    if (switchStatusNew) {
      setImage(originalImage);
      setSwitchStatus([switchStatusNew, "Original Image"]);
    } else {
      setImage(mainImage);
      setSwitchStatus([switchStatusNew, "Improved Image"]);
    }
  }

  //helper to calculate tags field from array:
  function getTags() {
    const tags = [];
    for (const tag of selectedImg.attributes.Tags.data) {
      tags.push(tag.attributes.Name);
    }
    return tags.toString();
  }

  //animaiton variants based on click direction
  const variants = {
    enter: (direction) => {
      return {
        x: direction > 0 ? 1000 : direction == 0 ? 0 : -1000,
        opacity: direction == 0 ? 1 : 0,
      };
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
    exit: (direction) => {
      return {
        zIndex: 0,
        x: direction < 0 ? 1000 : direction == 0 ? 0 : -1000,
        opacity: direction == 0 ? 1 : 0,
      };
    },
  };

  //keypress handlers for modal right, left, close
  const modalReference = useRef(null);
  function handleKeyPress(event) {
    if (event.key === "ArrowRight") {
      if (selectedIndex != photographs.length - 1) nextImage();
    }
    if (event.key === "ArrowLeft") {
      if (selectedIndex != 0) previousImage();
    }
    if (event.key === "Escape") {
      setSelectedImg(null);
    }
  }
  useEffect(() => {
    modalReference.current.focus();
  }, []);

  return (
    <motion.div className="backdrop" onClick={handleClick} initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <div className="modalPopup" onKeyDown={handleKeyPress} tabIndex="0" ref={modalReference}>
        <div className="modal-close-button" alt="close" />
        <div className="modal-content">
          <div className="img-wrapper">
            <div className="next-arrow-wrapper">
              {selectedIndex != photographs.length - 1 && <SlArrowRight className="next-arrow" onClick={nextImage} />}
            </div>
            <div className="previous-arrow-wrapper">
              {selectedIndex != 0 && <SlArrowLeft className="previous-arrow" onClick={previousImage} />}
            </div>
            <AnimatePresence mode="wait" initial={false} custom={direction}>
              <motion.img
                className="modal-image"
                src={image}
                alt="enlarged pic"
                key={image}
                initial="enter"
                animate="center"
                exit="exit"
                transition={{
                  ease: "linear",
                  duration: 0.1,
                }}
                custom={direction}
                variants={variants}
              />
            </AnimatePresence>
            {selectedImg.attributes.Description && <div className="img-description">{selectedImg.attributes.Description}</div>}
          </div>
          <div className="img-info">
            <div className="img-meta-header">
              <div className="img-title">{selectedImg.attributes.Title}</div>
              <div className="switch-section">
                <span>{switchStatus[1]}</span>
                <Switch
                  onChange={handleOriginalSwitch}
                  checked={switchStatus[0]}
                  disabled={disabled}
                  className="react-switch"
                  offColor="#86d3ff"
                  offHandleColor="#00bfff"
                  onColor="#DCDCDC"
                  onHandleColor="#4e4e4e"
                  handleDiameter={30}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={20}
                  width={48}
                />
              </div>
            </div>
            <div className="general-metadata">
              {selectedImg.attributes.Date && (
                <div className="general-meta-prop" id="meta-date">
                  <img className="metadata-icon" src={process.env.PUBLIC_URL + "/icons/date.svg"}></img>
                  <div className="metadata-name">
                    {selectedImg.attributes.Date ? new Date(selectedImg.attributes.Date).toDateString() : "Unknown"}
                  </div>
                </div>
              )}
              <div className="general-meta-prop" id="meta-location">
                <img className="metadata-icon" src={process.env.PUBLIC_URL + "/icons/location.svg"}></img>
                <div className="metadata-name">
                  {selectedImg.attributes.Location ? selectedImg.attributes.Location.description : "Unknown"}
                </div>
              </div>
              {selectedImg.attributes.Album.data && (
                <div className="general-meta-prop" id="meta-album">
                  <img className="metadata-icon" src={process.env.PUBLIC_URL + "/icons/album.svg"}></img>
                  <div className="metadata-name">
                    {selectedImg.attributes.Album.data ? selectedImg.attributes.Album.data.attributes.Title : ""}
                  </div>
                </div>
              )}
              {getTags() && (
                <div className="general-meta-prop" id="meta-tags">
                  <img className="metadata-icon" src={process.env.PUBLIC_URL + "/icons/tag.svg"}></img>
                  <div className="metadata-name">{getTags()}</div>
                </div>
              )}
            </div>
            <div className="img-metadata">
              <div className="img-meta-prop" id="img-meta-camera">
                <div className="metadata-head">
                  <img className="metadata-icon" src={process.env.PUBLIC_URL + "/icons/photo-camera.svg"}></img>
                  <div className="metadata-name">Camera:</div>
                </div>
                <div className="metadata-content">{selectedImg.attributes.CameraType}</div>
              </div>
              <div className="img-meta-prop" id="img-meta-flength">
                <div className="metadata-head">
                  <img className="metadata-icon" src={process.env.PUBLIC_URL + "/icons/focal-length.svg"}></img>
                  <div className="metadata-name">Focal Length:</div>
                </div>
                <div className="metadata-content">{selectedImg.attributes.FocalLength}</div>
              </div>
              <div className="img-meta-prop" id="img-meta-exptime">
                <div className="metadata-head">
                  <img src={process.env.PUBLIC_URL + "/icons/exposure.svg"}></img>
                  <div className="metadata-name">Exposure:</div>
                </div>
                <div className="metadata-content">{selectedImg.attributes.Exposure}</div>
              </div>
              <div className="img-meta-prop" id="img-meta-iso">
                <div className="metadata-head">
                  <img src={process.env.PUBLIC_URL + "/icons/iso-outline.svg"}></img>
                  <div className="metadata-name">ISO:</div>
                </div>
                <div className="metadata-content">{selectedImg.attributes.ISO}</div>
              </div>
              <div className="img-meta-prop" id="img-meta-aperture">
                <div className="metadata-head">
                  <img src={process.env.PUBLIC_URL + "/icons/aperture.svg"}></img>
                  <div className="metadata-name">F-Number:</div>
                </div>
                <div className="metadata-content">{selectedImg.attributes.Aperture}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Modal;
