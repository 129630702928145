import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./ImageGrid.scss";
import { SERVER } from "../../Config.js";
import Select, { components } from "react-select";
import { PhotoAlbum } from "react-photo-album";
import Modal from "../modal/Modal";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingSpinner from "../loadingspinner/LoadingSpinner";
import { fetchData } from "../../api/fetchData.js";

const ImageGrid = ({ options }) => {
  //Example options object
  /*const options = {
      title: "All Photographs",
      displayBackButton: true,
      query: {
        filters: "",
      }
    }*/

  //photographs contain meta, images are just the url and dimensions for the gallery
  const [photographs, setPhotographs] = useState([]);
  const [images, setImages] = useState(null);

  //status variables to manage api paging
  const [currentApiPage, setCurrentApiPage] = useState();
  const [maxApiPage, setMaxApiPage] = useState(1);

  //options for sorting
  const sortOptions = [
    { key: "oldest", label: "↓ Added", value: "createdAt:desc" },
    { key: "newest", label: "↓ Created", value: "Date:desc" },
    { key: "oldest", label: "↑ Added", value: "createdAt:asc" },
    { key: "oldest", label: "↑ Created", value: "Date:asc" },
  ];
  const [selectedSortOption, setSelectedSortOption] = useState(sortOptions[0]);

  //options for filtering by tag
  const defaultTagFilterOptions = [{ key: "All", label: "All", value: "" }];
  const [tagFilterOptions, setTagFilterOptions] = useState(defaultTagFilterOptions);
  const [selectedTagFilterOption, setSelectedTagFilterOption] = useState(tagFilterOptions[0]);

  //backbutton
  let navigate = useNavigate();
  const routeChange = () => {
    let path = `/albums`;
    navigate(path);
  };

  //use stateRef as a hack to access images state from within callback below. This is probably not a longterm solution
  const stateRef = useRef();
  stateRef.current = photographs;

  //selected image for modal popup
  const [selectedImg, setSelectedImg] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const openModal = useCallback(({ index }) => {
    setSelectedImg(stateRef.current[index]);
    setSelectedIndex(index);
  }, []);

  //styles for select
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: "#393433",
      color: "white",
      borderColor: "white",
      minHeight: "30px",
      height: "30px",
      boxShadow: state.isFocused ? null : null,
      cursor: "pointer",
      "&:hover": {
        borderColor: "grey",
      },
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "30px",
      padding: "0 6px",
      color: "white",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "white",
    }),
    menu: (provided, state) => ({
      ...provided,
      color: "white",
      background: "#393433",
      marginTop: "-2px", //ugly workaround to make menu align directly below select box
    }),
    option: (provided, state) => ({
      ...provided,
      "&:hover": {
        color: "black",
        background: "white",
        cursor: "pointer",
      },
      backgroundColor: state.isSelected ? "rgba(0,0,0,0.5)" : state.isHovered ? "green" : false,
    }),
    input: (provided, state) => ({
      ...provided,
      margin: "0px",
      color: "white",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "30px",
    }),
  };

  //whenever photographs changes (for example by sort), update also images to reflect change in gallery
  useEffect(() => {
    updateImages();
  }, [photographs]);

  //whenever sort/filter option selected changes, reset api page (which indirectly triggers reset of photographs and reload)
  useEffect(() => {
    setCurrentApiPage(1);
  }, [selectedSortOption, selectedTagFilterOption]);

  //Check if apipage has been updated, if changed to 1 it means data has been resorted
  useEffect(() => {
    if (currentApiPage == 1) {
      //load tags for filter options
      loadTags();
      //this check seems to be only solution. Ideally, api page should be set to 1 and directly after photos loaded. But doesn't work due to react state update policies. So it's only possible to react on state change in a general listener like here.
      setPhotographs([]);
      loadPhotos();
    }
  }, [currentApiPage]);

  //Loads all possible tags from backend
  async function loadTags() {
    const newFilters = defaultTagFilterOptions;
    const tagPath = "/api/categories"; //url still "categories" as this was the name previously used instead of tags
    const result = await fetchData(tagPath);
    if (result.data.data) {
      for (const element of result.data.data) {
        newFilters.push({ key: element.attributes.Name, value: element.attributes.Name, label: element.attributes.Name });
      }
    }
    setTagFilterOptions(newFilters);
  }

  //Load photographs from backend and set api page to next
  function loadPhotos() {
    const externalFilterString = `${options.query.filters}`;
    const tagFilterString = selectedTagFilterOption.value ? `filters[Tags][Name][$in]=${selectedTagFilterOption.value}` : "";
    const sortString = `sort=${selectedSortOption.value}`;
    const paginationString = `pagination[page]=${currentApiPage}&pagination[pageSize]=20`;
    const photographsPath = `/api/photographs?populate=*&${paginationString}&${sortString}&${tagFilterString}&${externalFilterString}`;
    //const photographsPath = `/api/photographs?sort=${selectedSortOption.value}&pagination[page]=${currentApiPage}&pagination[pageSize]=10&populate=*&filters[Tags][Name][$in]=${selectedTagFilterOption.value}`;
    fetchData(photographsPath).then((result) => {
      setPhotographs((photographs) => [...photographs, ...result.data.data]);
      setCurrentApiPage((currentApiPage) => currentApiPage + 1);
      setMaxApiPage((maxApiPage) => result.data.meta.pagination.pageCount);
    });
  }

  function updateImages() {
    let images = [];
    photographs.forEach((image) => {
      images.push({
        /* If possible take medium resolution, if not existant take default url */
        src: image.attributes.Image.data.attributes.formats.medium
          ? SERVER + image.attributes.Image.data.attributes.formats.medium.url
          : SERVER + image.attributes.Image.data.attributes.url,
        width: image.attributes.Image.data.attributes.width,
        height: image.attributes.Image.data.attributes.height,
      });
    });
    setImages(images);
  }

  //react select dropdown styling
  const SortIndicator = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <img src={process.env.PUBLIC_URL + "/icons/sort.svg"} />
        </components.DropdownIndicator>
      )
    );
  };
  const TagFilterIndicator = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <img src={process.env.PUBLIC_URL + "/icons/tag.svg"} />
        </components.DropdownIndicator>
      )
    );
  };

  return (
    <div>
      <div className="control-panel">
        <div className="controls-wrapper">
          <div className="title-section">
            {options.displayBackButton && (
              <img className="back-icon" src={process.env.PUBLIC_URL + "/icons/backIconWhite.png"} onClick={routeChange}></img>
            )}
            <p>{options.title}</p>
          </div>
          <div className="controls-section">
            <Select
              className="tagfilter-select"
              options={tagFilterOptions}
              onChange={setSelectedTagFilterOption}
              placeholder="Filter Tags"
              defaultValue={tagFilterOptions[0]}
              isSearchable={false}
              styles={customStyles}
              components={{ DropdownIndicator: TagFilterIndicator }}
            />
            <Select
              className="sort-select"
              options={sortOptions}
              onChange={setSelectedSortOption}
              placeholder="Sort"
              defaultValue={sortOptions[0]}
              isSearchable={false}
              styles={customStyles}
              components={{ DropdownIndicator: SortIndicator }}
            />
          </div>
        </div>
      </div>
      <InfiniteScroll
        className="infinite-scrollpanel"
        dataLength={photographs.length}
        next={loadPhotos}
        hasMore={currentApiPage <= maxApiPage}
        loader={
          <div className="loading-spinner">
            <LoadingSpinner></LoadingSpinner>
          </div>
        }
        endMessage={""}
      >
        <div className="explore">
          {images && (
            <PhotoAlbum className="image-gallery" layout="rows" spacing={10} targetRowHeight={400} photos={images} onClick={openModal} />
          )}
          {selectedImg && (
            <Modal
              selectedImg={selectedImg}
              setSelectedImg={setSelectedImg}
              selectedIndex={selectedIndex}
              setSelectedIndex={setSelectedIndex}
              photographs={photographs}
            />
          )}
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default ImageGrid;
