import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom/client";
import "./ImageMap.scss";
import { fetchMarkers } from "../../api/fetchMarkers";
import { generateRoutes } from "../../api/generateRoutes";
import Popup from "./Popup";
import { fetchData } from "../../api/fetchData.js";
import Modal from "../modal/Modal";

import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl";

import marker from "./pin-icon.png";

//This is for some reason necessary to make the map appear:
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

//TODO: display popup before displaying modal
mapboxgl.accessToken = "pk.eyJ1IjoiYXNtYXJkcGluZyIsImEiOiJja2lvcjBnZWEwbDc5MnJtbHEwbGk5eThoIn0.SgcScrmb-zRoiUdDfvma7Q";

const ImageMap = () => {
  const [map, setMap] = useState(null);

  const [selectedImg, setSelectedImg] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [photographs, setPhotographs] = useState(null);

  const mapContainer = useRef(null);

  useEffect(() => {
    let images = [];

    const initializeMap = ({ setMap, mapContainer }) => {
      const map = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/streets-v11", // stylesheet location
        center: [70, 18],
        zoom: 1.8,
      });

      map.on("load", async () => {
        map.resize();

        //fetch all photographs
        const path = "/api/photographs?populate=*&pagination[pageSize]=100"; //TODO: instead paginate all pages, otherwise max 100 points on map
        const result = await fetchData(path);
        images = result.data.data;
        setPhotographs(images);

        //Travel Route layer --> currently disabled because not properly working with timestamps in wrong order
        // const routes = await generateRoutes();
        // for (const [index, route] of routes.entries()) {
        //   const routeName = "route" + index;
        //   map.addSource(routeName, {
        //     type: "geojson",
        //     data: {
        //       type: "Feature",
        //       properties: {},
        //       geometry: {
        //         type: "LineString",
        //         coordinates: [],
        //       },
        //     },
        //   });
        //   map.addLayer({
        //     id: routeName,
        //     type: "line",
        //     source: routeName,
        //     layout: {
        //       "line-join": "round",
        //       "line-cap": "round",
        //     },
        //     paint: {
        //       "line-color": route.color,
        //       "line-width": 2,
        //     },
        //   });
        //   map.getSource(routeName).setData(route);
        // }

        //Markers Layer
        // list of icons here: https://labs.mapbox.com/maki-icons
        //-> This wraps around the addSource and addLayer in order to avoid photo-icon loading warnings in console
        await map.loadImage(marker, async (error, image) => {
          if (error) throw error;
          // add image to the active style and make it SDF-enabled
          map.addImage("photo-icon", image, { sdf: true });

          // add the data source for new a feature collection with no features
          map.addSource("photograph-markers", {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: [],
            },
            // cluster: true,
            // clusterMaxZoom: 14, // Max zoom to cluster points on
            // clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
          });
          // fetch new data, on coordinates 10/50
          const photographMarkers = await fetchMarkers(images);
          // now add the layer, and reference the data source above by name
          map.addLayer({
            id: "photograph-markers-layer",
            source: "photograph-markers",
            type: "symbol",
            layout: {
              "icon-image": "photo-icon",
              "icon-padding": 0,
              "icon-allow-overlap": true,
              "icon-size": 0.8,
              "icon-anchor": "bottom-left",
              "icon-offset": [-5, 3],
            },
            paint: {
              "icon-color": ["get", "travelColor"],
            },
          });

          // update "photograph-markers" source with new data
          // all layers that consume the "photograph-markers" data source will be updated automatically
          map.getSource("photograph-markers").setData(photographMarkers);

          map.resize();
        });
      });

      // add popup when user clicks a point
      map.on("click", "photograph-markers-layer", (e) => {
        if (e.features.length) {
          const feature = e.features[0];
          //display image modal
          const popupImage = images.find((image) => image.id === feature.properties.imageId);
          setSelectedImg(popupImage);
          setSelectedIndex(images.indexOf(popupImage));
        }
      });

      //PREVIEW POPUP SECTION
      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
        offset: 18,
        className: "image-popup",
      });
      // change cursor to pointer when user hovers over a clickable feature
      map.on("mouseenter", "photograph-markers-layer", (e) => {
        if (e.features.length) {
          map.getCanvas().style.cursor = "pointer";
          const feature = e.features[0];
          const coordinates = feature.geometry.coordinates.slice();

          // create popup node
          const popupNode = document.createElement("div");
          const root = ReactDOM.createRoot(popupNode); // createRoot(container!) if you use TypeScript
          root.render(<Popup feature={feature} />);

          // Ensure that if the map is zoomed out such that multiple
          // copies of the feature are visible, the popup appears
          // over the copy being pointed to.
          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }
          // set popup on map
          popup.setLngLat(coordinates).setDOMContent(popupNode).addTo(map);
        }
      });
      // reset cursor to default when user is no longer hovering over a clickable feature
      map.on("mouseleave", "photograph-markers-layer", () => {
        map.getCanvas().style.cursor = "";
        popup.remove();
      });

      map.addControl(new mapboxgl.NavigationControl(), "bottom-right");

      // clean up on unmount
      return () => map.remove();
    };

    if (!map) initializeMap({ setMap, mapContainer });
  }, [map]);

  return (
    <div className="wrapper">
      <div ref={(el) => (mapContainer.current = el)} className="mapContainer"></div>
      <div id="imagemodal" className="imagemodal"></div>
      {selectedImg && (
        <Modal
          selectedImg={selectedImg}
          setSelectedImg={setSelectedImg}
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
          photographs={photographs}
        />
      )}
    </div>
  );
};

export default ImageMap;

//TODO: Clusters currently not viable because of different album colors
// map.addLayer({
//   id: "clusters",
//   type: "circle",
//   source: "photograph-markers",
//   filter: ["has", "point_count"],
//   paint: {
//     // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
//     // with three steps to implement three types of circles:
//     //   * Blue, 20px circles when point count is less than 100
//     //   * Yellow, 30px circles when point count is between 100 and 750
//     //   * Pink, 40px circles when point count is greater than or equal to 750
//     "circle-color": ["step", ["get", "point_count"], "#51bbd6", 10, "#f1f075", 20, "#f28cb1"],
//     "circle-radius": ["step", ["get", "point_count"], 20, 100, 30, 750, 40],
//   },
// });
// map.addLayer({
//   id: "cluster-count",
//   type: "symbol",
//   source: "photograph-markers",
//   filter: ["has", "point_count"],
//   layout: {
//     "text-field": ["get", "point_count_abbreviated"],
//     "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
//     "text-size": 12,
//   },
// });
// map.addLayer({
//   id: "unclustered-point",
//   type: "circle",
//   source: "photograph-markers",
//   filter: ["!", ["has", "point_count"]],
//   paint: {
//     "circle-color": "#11b4da",
//     "circle-radius": 4,
//     "circle-stroke-width": 1,
//     "circle-stroke-color": "#fff",
//   },
// });
