import "./Navbar.scss";
import { NavLink } from "react-router-dom";
import React from "react";
import { slide as Menu } from "react-burger-menu";

const Navbar = () => {
  return (
    <div className="nav-section">
      <nav className="navbar">
        <div className="navbar--logo-holder title">
          <a className="branding" href="/featured">
            <h1>Elena Brendecke</h1>
          </a>
        </div>
        <div className="navbar--link">
          <NavLink className={({ isActive }) => (isActive ? "navbar--link-item-selected" : "navbar--link-item")} to="/featured">
            Featured
          </NavLink>
          <NavLink className={({ isActive }) => (isActive ? "navbar--link-item-selected" : "navbar--link-item")} to="/explore">
            Explore
          </NavLink>
          <NavLink className={({ isActive }) => (isActive ? "navbar--link-item-selected" : "navbar--link-item")} to="/albums">
            Albums
          </NavLink>
          <NavLink className={({ isActive }) => (isActive ? "navbar--link-item-selected" : "navbar--link-item")} to="/map">
            Map
          </NavLink>
        </div>
      </nav>
      <Menu className="navbar-mobile" width={ '100%' }>
        <a id="home" className="menu-item" href="/featured">
          Featured
        </a>
        <a id="about" className="menu-item" href="/explore">
          Explore
        </a>
        <a id="contact" className="menu-item" href="/albums">
          Albums
        </a>
        <a className="menu-item--small" href="/map">
          Map
        </a>
      </Menu>
      <a className="mobile-branding" href="/featured">
        Elena Brendecke
      </a>
    </div>
  );
};
export default Navbar;
