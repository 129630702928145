import { SERVER } from "../Config.js";

/* src/api/fetchMarkers.js */
/**
 * A complete Coordinate Pair consisting of a latitude and longitude
 * @typedef {Object} CoordinatePair
 * @property {number} longitude - longitude coordinate
 * @property {number} latitude - latitude coordinate
 */

/**
 * Generates a GeoJSON FeatureCollection of random points based on
 * the center coordinates passed in.
 * @param {CoordinatePair} centerCoordinates - the {@link CoordinatePair} for the map center
 * @return {results} GeoJSON FeatureCollection
 */
export const fetchMarkers = (images) => {
  const newFeaturesList = [];
  for (let i = 0; i < images.length; i++) {
    const id = i;
    //only display img on map if it has assigned coordinates
    //give preference to geoCoordinates (recorded by camera), if not existant use coordinates of assigned location
    let latitude = null;
    let longitude = null;
    if (images[i].attributes.geoCoordinates?.latitude && images[i].attributes.geoCoordinates?.longitude) {
      latitude = images[i].attributes.geoCoordinates.latitude;
      longitude = images[i].attributes.geoCoordinates.longitude;
    } else if (images[i].attributes.Location?.lat && images[i].attributes.Location?.lng) {
      latitude = images[i].attributes.Location.lat;
      longitude = images[i].attributes.Location.lng;
    } else {
      continue;
    }
    const albumColor = images[i].attributes.Album?.data?.attributes.color;
    const markerColor = albumColor ? albumColor : "#000";
    newFeaturesList.push({
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [longitude, latitude],
      },
      properties: {
        id,
        name: images[i].attributes.Title,
        description: images[i].attributes.Description,
        imageURL: SERVER + images[i].attributes.Image.data.attributes.formats.thumbnail.url,
        imageId: images[i].id,
        travelColor: markerColor,
      },
    });
  }
  return Promise.resolve({
    type: "FeatureCollection",
    features: newFeaturesList,
  });
};

/**
 * Generates a random point within 0.025 radius of map center coordinates.
 * @param {CoordinatePair} centerCoordinates - the {@link CoordinatePair} for the map center
 * @return {CoordinatePair} randomly generated coordinate pair
 */
const getRandomCoordinate = ({ longitude: centerLon, latitude: centerLat }) => {
  const r = 0.025 * Math.sqrt(Math.random());
  const theta = Math.random() * 2 * Math.PI;
  const latitude = centerLat + r * Math.cos(theta);
  const longitude = centerLon + r * Math.sin(theta);
  return { longitude, latitude };
};
