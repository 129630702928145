import React, { useState, useEffect, useCallback, useRef } from "react";
import { useParams } from "react-router";
import "./Album.scss";
import ImageGrid from "../imagegrid/ImageGrid";

const Album = () => {
  const { album } = useParams();

  const albumOptions = {
    title: album,
    displayBackButton: true,
    query: {
      filters: `filters[Album][Title][$eqi]=${album}`,
    }
  }
  const [options, setOptions] = useState(albumOptions);

  return <ImageGrid options={options} />;
};

export default Album;
