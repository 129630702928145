import React from "react";
import Navbar from "./comps/header/Navbar";
import Featured from "./comps/featured/Featured";
import Explore from "./comps/explore/Explore";
import Albums from "./comps/albums/Albums";
import Album from "./comps/albums/Album";
import ImageMap from "./comps/imagemap/ImageMap";
import { Route, Routes, Navigate } from "react-router-dom";
import Footer from "./comps/footer/Footer";
import Imprint from "./comps/Imprint";
import Birthday from "./comps/birthday/Birthday";
import GiftBox from "./comps/birthday/GiftBox";



function App() {
  /*   console.log("Pengpho Frontend")
  console.log(process.env.REACT_APP_SERVER_URL)
  console.log("NODE_ENV: " + process.env.NODE_ENV) */
  return (
    <div className="App" id="app">
      <Navbar className="nav-section" />
      <div className="content-section">
        <Routes>
          <Route path="/" element={<Navigate to="/featured" />} />
          <Route exact path="/featured" element={<Featured />} />
          <Route exact path="/explore" element={<Explore />} />
          <Route exact path="/albums" element={<Albums />} />
          <Route exact path="/map" element={<ImageMap />} />
          {/** Dynamically loaded routes not in menu: **/}
          <Route path="/albums/:album" element={<Album />} />
          <Route exact path="/imprint" element={<Imprint />} />
          <Route exact path="/surprise" element={<Navigate to="/birthday" />} />
          <Route exact path="/birthday" element={<Birthday />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
