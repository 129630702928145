import React, { useState, useEffect, useCallback, useRef } from "react";
import "./GiftBox.scss";

const GiftBox = ({ bottom, left, source, link }) => {
  const [isActive, setActive] = useState(false);

  const toggleClass = () => {
    setActive(true);
  };

  return (
    <div className="giftbox" style={{ bottom: bottom, left: left }} onClick={toggleClass}>
      <div className="col-12 mt-5 d-flex justify-content-center">
        <div className="box">
          <div className={`box-body ${isActive ? "active" : ""}`}>
            <a className="link" href={link} target="blank">
              <img className="img" src={process.env.PUBLIC_URL + source}></img>
            </a>
            <div className="box-lid">
              <div className="box-bowtie"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GiftBox;
