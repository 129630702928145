import React, { useState, useEffect, useCallback, useRef } from "react";
import "./Birthday.scss";
import GiftBox from "./GiftBox";

const Birthday = () => {
  useEffect(() => {
    // helper functions
    const PI2 = Math.PI * 2;
    const random = (min, max) => (Math.random() * (max - min + 1) + min) | 0;
    const timestamp = (_) => new Date().getTime();

    // container
    class Birthday {
      constructor() {
        this.resize();

        // create a lovely place to store the firework
        this.fireworks = [];
        this.counter = 0;
      }

      resize() {
        this.width = canvas.width = window.innerWidth;
        let center = (this.width / 2) | 0;
        this.spawnA = (center - center / 4) | 0;
        this.spawnB = (center + center / 4) | 0;

        this.height = canvas.height = window.innerHeight;
        this.spawnC = this.height * 0.1;
        this.spawnD = this.height * 0.5;
      }

      onClick(evt) {
        let x = evt.clientX || (evt.touches && evt.touches[0].pageX);
        let y = evt.clientY || (evt.touches && evt.touches[0].pageY);

        let count = random(3, 5);
        for (let i = 0; i < count; i++)
          this.fireworks.push(new Firework(random(this.spawnA, this.spawnB), this.height, x, y, random(0, 260), random(30, 110)));

        this.counter = -1;
      }

      update(delta) {
        ctx.globalCompositeOperation = "hard-light";
        ctx.fillStyle = `rgba(20,20,20,${7 * delta})`;
        ctx.fillRect(0, 0, this.width, this.height);

        ctx.globalCompositeOperation = "lighter";
        for (let firework of this.fireworks) firework.update(delta);

        // if enough time passed... create new new firework
        this.counter += delta * 3; // each second
        if (this.counter >= 1) {
          this.fireworks.push(
            new Firework(
              random(this.spawnA, this.spawnB),
              this.height,
              random(0, this.width),
              random(this.spawnC, this.spawnD),
              random(0, 360),
              random(30, 110)
            )
          );
          this.counter = 0;
        }

        // remove the dead fireworks
        if (this.fireworks.length > 1000) this.fireworks = this.fireworks.filter((firework) => !firework.dead);
      }
    }

    class Firework {
      constructor(x, y, targetX, targetY, shade, offsprings) {
        this.dead = false;
        this.offsprings = offsprings;

        this.x = x;
        this.y = y;
        this.targetX = targetX;
        this.targetY = targetY;

        this.shade = shade;
        this.history = [];
      }
      update(delta) {
        if (this.dead) return;

        let xDiff = this.targetX - this.x;
        let yDiff = this.targetY - this.y;
        if (Math.abs(xDiff) > 3 || Math.abs(yDiff) > 3) {
          // is still moving
          this.x += xDiff * 2 * delta;
          this.y += yDiff * 2 * delta;

          this.history.push({
            x: this.x,
            y: this.y,
          });

          if (this.history.length > 20) this.history.shift();
        } else {
          if (this.offsprings && !this.madeChilds) {
            let babies = this.offsprings / 2;
            for (let i = 0; i < babies; i++) {
              let targetX = (this.x + this.offsprings * Math.cos((PI2 * i) / babies)) | 0;
              let targetY = (this.y + this.offsprings * Math.sin((PI2 * i) / babies)) | 0;

              birthday.fireworks.push(new Firework(this.x, this.y, targetX, targetY, this.shade, 0));
            }
          }
          this.madeChilds = true;
          this.history.shift();
        }

        if (this.history.length === 0) this.dead = true;
        else if (this.offsprings) {
          for (let i = 0; this.history.length > i; i++) {
            let point = this.history[i];
            ctx.beginPath();
            ctx.fillStyle = "hsl(" + this.shade + ",100%," + i + "%)";
            ctx.arc(point.x, point.y, 1, 0, PI2, false);
            ctx.fill();
          }
        } else {
          ctx.beginPath();
          ctx.fillStyle = "hsl(" + this.shade + ",100%,50%)";
          ctx.arc(this.x, this.y, 1, 0, PI2, false);
          ctx.fill();
        }
      }
    }

    let canvas = document.getElementById("birthday");
    let ctx = canvas.getContext("2d");

    let then = timestamp();

    let birthday = new Birthday();
    window.onresize = () => birthday.resize();
    document.onclick = (evt) => birthday.onClick(evt);
    document.ontouchstart = (evt) => birthday.onClick(evt);
    (function loop() {
      requestAnimationFrame(loop);

      let now = timestamp();
      let delta = now - then;

      then = now;
      birthday.update(delta / 1000);
    })();
  }, []);

  return (
    <div className="birthday" style={{ marginBottom: "10rem" }}>
      <h1>Happy Birthday! 🐼🐧</h1>
      <canvas id="birthday"></canvas>
      <GiftBox
        left="25%"
        bottom="200px"
        source="/surpriseImgs/neni.jpg"
        link="https://www.google.com/maps/place/NENI+Berlin/@52.5056581,13.33532,17z/data=!3m1!4b1!4m6!3m5!1s0x47a85055611510f9:0x7f67d148e844fc21!8m2!3d52.5056581!4d13.3378949!16s%2Fg%2F1pxq5lhls?entry=ttu"
      ></GiftBox>
      <GiftBox
        left="50%"
        bottom="200px"
        source="/surpriseImgs/teleskop.jpg"
        link="https://www.google.com/maps/place/Wilhelm-Foerster-Sternwarte/@52.458361,13.3485611,17.88z/data=!3m1!5s0x47a85aa167313749:0x52342bc5c43e23d8!4m6!3m5!1s0x47a85aa10a99ab1b:0x7261e26d183d7fd4!8m2!3d52.4573997!4d13.3512043!16s%2Fg%2F122drpf7?entry=ttu"
      ></GiftBox>
      {/* <GiftBox left="60%" bottom="200px" source="/surprise/iphone12.png"></GiftBox> */}
      <GiftBox
        left="75%"
        bottom="200px"
        source="/surpriseImgs/question.webp"
        link="https://www.google.com/maps/place/Mollwitzstra%C3%9Fe+5A,+14059+Berlin/@52.5221739,13.2859767,17z/data=!3m1!4b1!4m6!3m5!1s0x47a851874a67ff5d:0xec90f589419952e4!8m2!3d52.5221739!4d13.2885516!16s%2Fg%2F11j65hkz4v?entry=ttu"
      ></GiftBox>
    </div>
  );
};

export default Birthday;
