import React, { useEffect, useState, useRef } from "react";
import "./Featured.scss";
import { fetchData } from "../../api/fetchData.js";
import { FaArrowAltCircleRight, FaArrowAltCircleLeft } from "react-icons/fa";
import { SERVER } from "../../Config.js";
import { useSwipeable } from "react-swipeable";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination, Mousewheel, Keyboard } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const Featured = () => {
  const [featuredIndex, setFeaturedIndex] = useState(0);
  const [featuredImagesArray, setFeaturedImagesArray] = useState();

  //window width checker, only works on reload.
  const windowWidth = useRef(window.innerWidth);

  const handlers = useSwipeable({
    onSwipedRight: (eventData) => prevSlide(),
    onSwipedLeft: (eventData) => nextSlide(),
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  //animations:
  const [animation, setAnimation] = useState("left");

  //fetch all featured photographs from server
  useEffect(() => {
    const path = "/api/featureds?populate[photograph][populate][0]=Image&sort=rank:asc";
    fetchData(path).then((result) => {
      const featuredPhotographs = result.data.data;
      // featuredPhotographs.sort(function (a, b) {
      //   return new Date(b.attributes.createdAt) - new Date(a.attributes.createdAt);
      // });
      const images = [];
      featuredPhotographs.map((entry) => {
        const largeFormat = entry.attributes.photograph.data.attributes.Image.data.attributes.formats.large;
        const defaultFormat = entry.attributes.photograph.data.attributes.Image.data.attributes.url;

        let imageUrl = defaultFormat;

        if (largeFormat) {
          imageUrl = largeFormat.url;
        }

        if (windowWidth.current < 768) {
          if (entry.attributes.mobile_capable == true) {
            images.push(SERVER + imageUrl);
          }
        } else {
          if (entry.attributes.mobile_capable != true) {
            images.push(SERVER + imageUrl);
          }
        }
      });
      setFeaturedImagesArray(images);
    });
  }, []);

  const nextSlide = () => {
    setFeaturedIndex(featuredIndex === featuredImagesArray.length - 1 ? 0 : featuredIndex + 1);
    setAnimation("right");
  };

  const prevSlide = () => {
    setFeaturedIndex(featuredIndex === 0 ? featuredImagesArray.length - 1 : featuredIndex - 1);
    setAnimation("left");
  };

  if (!Array.isArray(featuredImagesArray) || featuredImagesArray.length <= 0) {
    return null;
  }

  return (
    <div className="featured">
      <div className="container">
        <div className="carousel-wrapper">
          <Swiper
            cssMode={true}
            navigation={true}
            pagination={true}
            mousewheel={true}
            keyboard={true}
            loop={true}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay, Navigation, Pagination, Mousewheel, Keyboard]}
            className="carousel"
          >
            {featuredImagesArray.map((slide, index) => {
              return (
                <SwiperSlide className="carousel-slide" key={index}>
                  <img className="carousel-img" src={slide} alt={slide} />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Featured;

/*      <section {...handlers} className="slider">
        {featuredImagesArray.map((slide, index) => {
          return (
            <div className="keyholder" key={index}>
              <div className={index === featuredIndex ? "slide active" : "slide"} key={index}>
                {index === featuredIndex && <img src={slide} alt={slide} className={`image ${animation}`} />}
              </div>
              <FaArrowAltCircleLeft className="left-arrow" onClick={prevSlide} />
              <FaArrowAltCircleRight className="right-arrow" onClick={nextSlide} />
            </div>
          );
        })}
      </section>
      */
