import axios from "axios";
import {SERVER} from "../Config.js";


//const SERVER = "http://localhost:1337";

export function fetchData(path) {
    return axios
        .get(SERVER + path)
        .then(result => {
            return {
                data: result.data
            }
        }).catch(error => {
            console.log("Fetching data from server failed with error: " + error)
            return {
                data: error
            }
        });
}

