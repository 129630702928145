import React, { useState} from "react";
import ImageGrid from "../imagegrid/ImageGrid";

import "./Explore.scss";

const Explore = () => {
  const exploreOptions = {
    title: "All Photographs",
    displayBackButton: false,
    query: {
      filters: "",
    }
  }
  const [options, setOptions] = useState(exploreOptions);

  return <ImageGrid options={options} />;
};

export default Explore;
