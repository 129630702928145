import React from "react";
import { NavLink } from 'react-router-dom';
import "./Footer.scss"

const Footer = () => (
  <div className="footer">
    <p className="footer--text-item"> &copy; Elena Brendecke & Yannic Lades, {(new Date().getFullYear())}</p>
    <NavLink className="footer--link-item" to='/imprint'>Impressum</NavLink>
  </div>
);

export default Footer;