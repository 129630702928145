/* src/components/Popup.js */
import React from "react";

const Popup = ({ feature }) => {
  const { id, name, description, imageURL } = feature.properties;

  return (
    <div id={`popup-${id}`}>
      {/*       <h3>{name}</h3>
      {description} */}
      <img src={imageURL} alt="uploaded pic" style={{ width: "100%", display: "block"}} />
    </div>
  );
};

export default Popup;
